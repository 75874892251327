import cx from 'classnames';
import { WIKI_REVIEW_CATEGORY__MORE_ARTICLE_PREVIEWS } from 'mk/autogenerated/translations/ReviewArticles.171ae160d2cde048925c1ab697901ded'
import assetUrl from 'mk/common/assetUrl';
import ReviewArticlePreview, {
    LayoutType as ReviewArticlePreviewLayoutType,
    ReviewArticlePreviewPlaceholder,
    ThumbnailSize,
} from 'mk2/apps/wiki/components/ReviewArticlePreview';
import { Btn, BtnSidePadding, BtnType } from 'mk2/components/Btn';
import { Img } from 'mk2/components/Img';
import { Link } from 'mk2/components/Link';
import { LoadingState } from 'mk2/reducers/loadingState';
import { WikiArticleEntity } from 'mk2/schemas';
import React from 'react';
import styles from './ReviewArticles.mscss';

export enum LayoutType {
    // check storybook
    DEFAULT = 1,
    COMPACT = 2,
    HORIZONTAL = 3,
}

interface OwnProps {
    className?: string;
    header?:  string;
    headerLinkTo?:  string;
    headerLogo?:  boolean;
    count?: number;
    articles: WikiArticleEntity[];
    layout: LayoutType;
    loadingState?: LoadingState;
    onLoadMore?();
}

type Props = OwnProps;

class ReviewArticles extends React.PureComponent<Props> {
    public render() {
        const {
            className,
            articles,
            count,
            header,
            headerLinkTo,
            headerLogo,
            layout,
            loadingState,
            onLoadMore,
        } = this.props;

        const reviewArticlePreviewLayout = {
            [LayoutType.DEFAULT]: ReviewArticlePreviewLayoutType.DEFAULT,
            [LayoutType.COMPACT]: ReviewArticlePreviewLayoutType.COMPACT,
            [LayoutType.HORIZONTAL]: ReviewArticlePreviewLayoutType.HORIZONTAL,
        }[layout];
        const reviewArticlePreviewThumbnailSize = {
            [LayoutType.DEFAULT]: ThumbnailSize.LARGE,
            [LayoutType.COMPACT]: ThumbnailSize.SMALLEST,
            [LayoutType.HORIZONTAL]: ThumbnailSize.LARGEST,
        }[layout];
        const logoSize = {
            [LayoutType.DEFAULT]: 36,
            [LayoutType.COMPACT]: 24,
            [LayoutType.HORIZONTAL]: 36,
        }[layout];

        return (
            <div
                className={
                    cx(
                        styles.ReviewArticles,
                        className,
                        {
                            [LayoutType.DEFAULT]: styles['ReviewArticles--default'],
                            [LayoutType.COMPACT]: styles['ReviewArticles--compact'],
                            [LayoutType.HORIZONTAL]: styles['ReviewArticles--compact'],
                        }[layout],
                    )
                }
            >
                {header && (
                    <h2
                        className={cx(
                            styles.ReviewArticles__heading,
                            {
                                [LayoutType.DEFAULT]: null,
                                [LayoutType.COMPACT]: styles['ReviewArticles__heading--compact'],
                                [LayoutType.HORIZONTAL]: styles['ReviewArticles__heading--horizontal'],
                            }[layout],
                        )}
                    >
                        {headerLogo &&
                            <Img
                                className={styles.ReviewArticles__heading__logo}
                                src={assetUrl + 'img/miky/sitting-miky.png'}
                                width={logoSize}
                                height={logoSize}
                            />
                        }
                        {header && (headerLinkTo
                            ? <Link to={headerLinkTo}>{header}</Link> : header)
                        }
                        {count ? (
                            <>
                                &nbsp;<span className={styles.ReviewArticles__count}>({count})</span>
                            </>
                        ) : (
                            ''
                        )}
                    </h2>
                )}
                <div
                    className={cx({
                        [LayoutType.DEFAULT]: null,
                        [LayoutType.COMPACT]: null,
                        [LayoutType.HORIZONTAL]: styles['ReviewArticles__items--horizontal'],
                    }[layout])}
                >
                    {articles?.map((article, idx) => (
                        <div
                            key={idx}
                            className={cx(
                                {
                                    [LayoutType.DEFAULT]: null,
                                    [LayoutType.COMPACT]: null,
                                    [LayoutType.HORIZONTAL]: styles['ReviewArticles__item--horizontal'],
                                }[layout],
                                (articles?.length !== idx + 1) && {
                                    [LayoutType.DEFAULT]: null,
                                    [LayoutType.COMPACT]: styles['ReviewArticles__itemBorder--compact'],
                                    [LayoutType.HORIZONTAL]: styles['ReviewArticles__itemBorder--horizontal'],
                                }[layout],
                            )}
                        >
                            <ReviewArticlePreview
                                article={article}
                                layout={reviewArticlePreviewLayout}
                                thumbnailSize={reviewArticlePreviewThumbnailSize}
                            />
                        </div>
                    ))}
                </div>
                {loadingState === LoadingState.LOADING && (
                    <>
                        {layout === LayoutType.COMPACT ? (
                            <>
                                <ReviewArticlePreviewPlaceholder
                                    thumbnailSize={ThumbnailSize.SMALLEST}
                                    noBorder
                                />
                                <ReviewArticlePreviewPlaceholder
                                    thumbnailSize={ThumbnailSize.SMALLEST}
                                    noBorder
                                />
                            </>
                        ) : (
                            <>
                                <ReviewArticlePreviewPlaceholder/>
                                <ReviewArticlePreviewPlaceholder/>
                                <ReviewArticlePreviewPlaceholder/>
                            </>
                        )}
                    </>
                )}
                {onLoadMore && loadingState === LoadingState.INIT && (
                    <Btn
                        onClick={onLoadMore}
                        type={BtnType.Link}
                        className={styles.ReviewArticles__more}
                        sidePadding={BtnSidePadding.None}
                        label={WIKI_REVIEW_CATEGORY__MORE_ARTICLE_PREVIEWS}
                    />
                )}
            </div>
        );
    }
}

export default ReviewArticles;
