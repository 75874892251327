// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const REVIEW_ARTICLE_SORT_EXPERIENCES_BY_BEST = "Se\u0159adit od nejlep\u0161\u00edch";
export const REVIEW_ARTICLE_SORT_EXPERIENCES_BY_LIKES = "Neju\u017eite\u010dn\u011bj\u0161\u00ed hodnocen\u00ed";
export const REVIEW_ARTICLE_SORT_EXPERIENCES_BY_NEWEST = "Nejnov\u011bj\u0161\u00ed hodnocen\u00ed";
export const REVIEW_ARTICLE_SORT_EXPERIENCES_BY_PHOTOS = "Hodnocen\u00ed s fotkama";
export const REVIEW_ARTICLE_SORT_EXPERIENCES_BY_WORST = "Se\u0159adit od nejhor\u0161\u00edch";
export const WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_ANY_REVIEWABLE = "M\u00e1\u0161 zku\u0161enost produktem, slu\u017ebou nebo m\u00edstem?";
export const WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_title = "M\u00e1\u0161 zku\u0161enost %(title)s?";
export const WIKI_EXP_AVERAGE_SCORE = "Pr\u016fm\u011brn\u00e9 hodnocen\u00ed:";
export const WIKI_EXPERIENCES_WITH_REVIEWS__HP = "Nejnov\u011bj\u0161\u00ed zku\u0161enosti";
export const WIKI_EXPERIENCES_WITH_title = "Hodnocen\u00ed a zku\u0161enosti %(title)s";
export const WIKI_REVIEW_CATEGORY__NEW_ARTICLES_OF_category = "Nov\u00e9 v kategorii %(category)s";
export const WIKI_SHARE_YOUR_EXPERIENCE_AND_HELP_OTHERS = "Pod\u011bl se o ni a pomoz tak ostatn\u00edm mamink\u00e1m.";
export const WIKI_WHERE_NEXT = "Kam d\u00e1l?";
export const WIKI_WRITE_YOUR_EXPERIENCE = "Napi\u0161 svou zku\u0161enost";
