import { faComment } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import {
    REVIEW_ARTICLE_SORT_EXPERIENCES_BY_BEST,
    REVIEW_ARTICLE_SORT_EXPERIENCES_BY_LIKES,
    REVIEW_ARTICLE_SORT_EXPERIENCES_BY_NEWEST,
    REVIEW_ARTICLE_SORT_EXPERIENCES_BY_PHOTOS,
    REVIEW_ARTICLE_SORT_EXPERIENCES_BY_WORST,
    WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_title,
    WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_ANY_REVIEWABLE,
    WIKI_EXP_AVERAGE_SCORE,
    WIKI_EXPERIENCES_WITH_title,
    WIKI_EXPERIENCES_WITH_REVIEWS__HP,
    WIKI_REVIEW_CATEGORY__NEW_ARTICLES_OF_category,
    WIKI_SHARE_YOUR_EXPERIENCE_AND_HELP_OTHERS,
    WIKI_WHERE_NEXT,
    WIKI_WRITE_YOUR_EXPERIENCE,
} from 'mk/autogenerated/translations/ReviewArticle.1343f76c33c40785aee98ecb34113427'
import { isAuthenticated } from 'mk/bazaar/common/userUtils';
import SimpleSelect from 'mk2/apps/bazaar/components/SimpleSelect';
import { renderCover } from 'mk2/apps/blogs/containers/Article/Article.helpers';
import { WikiArticles } from 'mk2/apps/home/components/WikiArticles';
import { strollersPrefix } from 'mk2/apps/strollers/urls';
import { loginUrl } from 'mk2/apps/users/urls';
import ArticleBody from 'mk2/apps/wiki/components/ArticleBody';
import Experience from 'mk2/apps/wiki/components/Experience';
import { LayoutType } from 'mk2/apps/wiki/components/ReviewArticles';
import ReviewCategoryPreview from 'mk2/apps/wiki/components/ReviewCategoryPreview';
import Article from 'mk2/apps/wiki/containers/Article/Article';
import { Props } from 'mk2/apps/wiki/containers/Article/ReviewArticlePage';
import ReviewHomepageRecentBlogArticles from 'mk2/apps/wiki/containers/ReviewHomepageRecentBlogArticles/ReviewHomepageRecentBlogArticles';
import { isArticleOfReviewsCategory, isReviewHomepageArticle } from 'mk2/apps/wiki/helpers';
import {
    getWikiArticleUrl,
    wikiAddExperienceToUndefinedArticleUrl,
    wikiAddExperienceUrl,
    wikiArticlePhotoUrl,
} from 'mk2/apps/wiki/urls';
import { BreadcrumbSiteStructure } from 'mk2/components/BreadcrumbSiteStructure';
import { Btn, BtnType } from 'mk2/components/Btn';
import { PhotosRowOfSquares } from 'mk2/components/PhotosRowOfSquares';
import { StarsRating } from 'mk2/components/StarsRating';
import { TrackClick } from 'mk2/components/TrackClick';
import { LoadingSwitch } from 'mk2/containers/LoadingSwitch/LoadingSwitch';
import { cacheLast } from 'mk2/helpers/cache';
import { hasVal } from 'mk2/helpers/sanitize';
import { scrollElementTo } from 'mk2/helpers/scrolling';
import { url } from 'mk2/helpers/urls';
import Loadable from 'mk2/helpers/Loadable';
import { isSuccess, LoadingState } from 'mk2/reducers/loadingState';
import { ExperienceEntity, PhotoEntity } from 'mk2/schemas';
import { interpolate } from 'mk2/services/i18n';
import React from 'react';
import styles from './Article.mscss';

const ReviewArticles = Loadable({
    loader: () => import('mk2/apps/wiki/components/ReviewArticles' /* webpackChunkName: "wiki.ReviewArticles" */),
    modules: ['mk2/apps/wiki/components/ReviewArticles'],
    webpack: () => [require.resolveWeak('mk2/apps/wiki/components/ReviewArticles')],
});

interface OwnState {
    sortExperiencesBy: SortExperiencesBy;
    collapsedBreadcrumbs: boolean;
    articleSlug: string;
}

enum SortExperiencesBy {
    NEWEST = 'newest',
    BEST = 'best',
    WORST = 'worst',
    PHOTOS = 'photos',
    LIKES = 'likes',
}

const sortOptions: Record<SortExperiencesBy, string> = {
    [SortExperiencesBy.NEWEST]: REVIEW_ARTICLE_SORT_EXPERIENCES_BY_NEWEST,
    [SortExperiencesBy.BEST]: REVIEW_ARTICLE_SORT_EXPERIENCES_BY_BEST,
    [SortExperiencesBy.WORST]: REVIEW_ARTICLE_SORT_EXPERIENCES_BY_WORST,
    [SortExperiencesBy.LIKES]: REVIEW_ARTICLE_SORT_EXPERIENCES_BY_LIKES,
    [SortExperiencesBy.PHOTOS]: REVIEW_ARTICLE_SORT_EXPERIENCES_BY_PHOTOS,
};

const sortingFns: Record<SortExperiencesBy, (a: ExperienceEntity, b: ExperienceEntity) => number> = {
    [SortExperiencesBy.NEWEST]: (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime(),
    [SortExperiencesBy.BEST]: (a, b) =>
        b.starsCount - a.starsCount || new Date(b.created).getTime() - new Date(a.created).getTime(),
    [SortExperiencesBy.WORST]: (a, b) =>
        a.starsCount - b.starsCount || new Date(b.created).getTime() - new Date(a.created).getTime(),
    [SortExperiencesBy.PHOTOS]: (a, b) =>
        b.photos.length - a.photos.length || new Date(b.created).getTime() - new Date(a.created).getTime(),
    [SortExperiencesBy.LIKES]: (a, b) =>
        b.likeable.likesCount - a.likeable.likesCount || new Date(b.created).getTime() - new Date(a.created).getTime(),
};

const MAX_WIDTH = 584;

class ReviewArticle extends React.Component<Props, OwnState> {
    public static getDerivedStateFromProps(props: Props, state: OwnState) {
        if (props.articleSlug !== state.articleSlug) {
            return {
                articleSlug: props.articleSlug,
                collapsedBreadcrumbs: true,
            };
        }

        return null;
    }

    public state: OwnState = {
        collapsedBreadcrumbs: true,
        articleSlug: this.props.articleSlug,
        sortExperiencesBy: SortExperiencesBy.NEWEST,
    };

    private sortedExperiences = cacheLast<ExperienceEntity[]>(true);

    public componentDidMount() {
        const { articleSlug, categorySlug, experienceIdRef, loadingState, onPageView } = this.props;
        onPageView(categorySlug, articleSlug);

        // Scroll only if content is loaded
        if (experienceIdRef && isSuccess(loadingState)) {
            try {
                scrollElementTo(experienceIdRef, 'window-top');
            } catch (error) {
                // ignore, no need to show error page because of this
            }
        }
    }

    public componentDidUpdate(prevProps: Props) {
        const { articleSlug, categorySlug, experienceIdRef, loadingState, onPageView } = this.props;

        if (prevProps.match.params.articleSlug !== articleSlug) {
            onPageView(categorySlug, articleSlug);
        }

        if (
            experienceIdRef &&
            prevProps.loadingState === LoadingState.LOADING &&
            loadingState === LoadingState.SUCCESS
        ) {
            // Content is now loaded
            scrollElementTo(experienceIdRef, 'window-top');
        }
    }

    public render() {
        const { loadingState, article } = this.props;
        return (
            <LoadingSwitch
                loadingState={loadingState}
                onLoad={this.load}
                onRenderInit={this.renderInit}
                onRenderSuccess={this.renderLoadSuccess}
                onRenderFailure={this.renderFailure}
                hasData={!!article}
            />
        );
    }

    private load = () => {
        const { articleSlug, categorySlug, onLoad } = this.props;
        onLoad(articleSlug, categorySlug);
    };

    private renderInit = () => {
        const { articleSlug, isMobile } = this.props;
        return (
            <>
                {Article.renderInit(isMobile)}
                <ReviewHomepageRecentBlogArticles articleSlug={articleSlug} key={'recent-blog-articles'} />
            </>
        );
    };

    private renderFailure = () => {
        const { staticContext, errorMessage } = this.props;
        return Article.renderFailure(staticContext, errorMessage, this.load);
    };

    private renderLoadSuccess = () => {
        const {
            article,
            articleSlug,
            articleConceptPath,
            isMobile,
            location,
            onDeleteVendorReply,
            onSaveVendorReply,
            onToggleMenuMore,
            relatedArticles,
            requestUser,
            requestUserPermissions,
            reviewCategoryArticles,
            reviewCategoryArticlesCount,
            reviewCategoryExperiences,
            reviewCategories,
            reviewCategoriesStats,
            viewsCountUnique,
            viewsCountTotal,
            moreReviewCategoryArticlesLoadingState,
            eshopProductsOfEshopNodes,
        } = this.props;

        const cover = renderCover(article);

        const isAdmin = requestUserPermissions.indexOf('wiki.can_edit_wiki') >= 0;

        /**
         * - Review homepage article
         *   - Review category article
         *     - Review category article
         *       - Review product article
         *       - Review product article
         *     - Review product article
         *     - Review product article
         *   - Review category article
         *     - Review product article
         */
        const isReviewCategory = hasVal(reviewCategoryArticlesCount);
        const isReviewHomepageOrCategoryOrProduct = isArticleOfReviewsCategory(article.category.slug);
        const isReviewProduct = isReviewHomepageOrCategoryOrProduct && !isReviewCategory;

        const showAlsoCurrentArticleInBreadcrumbs = isReviewCategory;

        const experiences = this.sortedExperiences(
            () => {
                const exps = isReviewCategory ? reviewCategoryExperiences : article.experiences;
                return exps.sort(sortingFns[this.state.sortExperiencesBy]);
            },
            isReviewCategory,
            reviewCategoryExperiences,
            article.experiences,
            this.state.sortExperiencesBy,
        );

        const subCategoriesOfCurrentCategory =
            isReviewCategory && reviewCategories ? (
                <>
                    {reviewCategories.map((reviewCategory) => (
                        <ReviewCategoryPreview
                            key={reviewCategory.id}
                            name={reviewCategory.article.title}
                            faIcon={reviewCategory.icon}
                            url={getWikiArticleUrl(reviewCategory.article.category.slug, reviewCategory.article.slug)}
                            reviewArticleCount={reviewCategoriesStats?.[reviewCategory.article.id]?.productsCount}
                            reviewExperiencesCount={
                                reviewCategoriesStats?.[reviewCategory.article.id]?.experiencesCount
                            }
                        />
                    ))}
                </>
            ) : null;
        const reviewArticlesOfCurrentCategory = isReviewCategory ? (
            <ReviewArticles
                header={interpolate(WIKI_REVIEW_CATEGORY__NEW_ARTICLES_OF_category, { category: article.title })}
                articles={reviewCategoryArticles}
                count={reviewCategoryArticlesCount}
                onLoadMore={
                    reviewCategoryArticles.length === reviewCategoryArticlesCount
                        ? null
                        : this.handleOnLoadMoreReviewCategoryArticles
                }
                layout={LayoutType.DEFAULT}
                loadingState={moreReviewCategoryArticlesLoadingState}
            />
        ) : null;

        const photosInPhotosRow = isReviewProduct
            ? cover
                ? article.photos.filter((photo) => photo.code !== cover.photoCode)
                : article.photos
            : null;

        const addExpUrl = !isReviewCategory
            ? url(wikiAddExperienceUrl, {
                  articleSlug: article.slug,
                  categorySlug: article.category.slug,
              })
            : url(wikiAddExperienceToUndefinedArticleUrl, {});
        return (
            <>
                <div className={styles.Article}>
                    {article.category.slug !== strollersPrefix && (
                        <BreadcrumbSiteStructure
                            className={styles.Article__breadcrumb}
                            items={
                                articleConceptPath.length > 4 && this.state.collapsedBreadcrumbs
                                    ? Article.getCollapsedBreadcrumbLinks(
                                          articleConceptPath,
                                          this.onClickSetState,
                                          showAlsoCurrentArticleInBreadcrumbs,
                                      )
                                    : Article.getBreadcrumbLinks(
                                          articleConceptPath,
                                          showAlsoCurrentArticleInBreadcrumbs,
                                      )
                            }
                        />
                    )}

                    {cover && <div className={styles.Article__cover}>{cover.rendered}</div>}

                    {photosInPhotosRow && (
                        <PhotosRowOfSquares
                            className={cx(
                                styles.Article__photosRow,
                                article.photos.length > 6
                                    ? styles['Article__photosRow--stretchedAndCentered']
                                    : styles['Article__photosRow--alignLeft'],
                            )}
                            photoVersion="s246x246"
                            maxWidth={MAX_WIDTH}
                            photos={photosInPhotosRow}
                            linkTo={this.galleryLinkTo}
                        />
                    )}

                    <ArticleBody
                        article={article}
                        location={location}
                        viewsCountUnique={viewsCountUnique}
                        viewsCountTotal={viewsCountTotal}
                        followLinks
                        isMobile={isMobile}
                        isReviewCategory={isReviewCategory}
                        requestUser={requestUser}
                        requestUserPermissions={requestUserPermissions}
                        eshopProductsOfEshopNodes={eshopProductsOfEshopNodes}
                    />

                    {subCategoriesOfCurrentCategory}
                    {reviewArticlesOfCurrentCategory}
                    <ReviewHomepageRecentBlogArticles articleSlug={articleSlug} key={'recent-blog-articles'} />
                </div>

                {article.category.slug !== strollersPrefix && relatedArticles.length > 0 && (
                    <>
                        <h2 className={styles.ArticleExperiences__sectionHeader}>{WIKI_WHERE_NEXT}</h2>
                        <WikiArticles
                            className={styles.Article__relatedArticles}
                            articlesData={relatedArticles}
                            isMobile={isMobile}
                            location={location}
                        />
                    </>
                )}

                {(article.experiencesAllowed || isReviewCategory) && (
                    <div id={'experiences'} className={styles.ArticleExperiences}>
                        <h2 className={styles.ArticleExperiences__sectionHeader}>
                            {isReviewHomepageArticle(article.category.slug, article.slug)
                                ? WIKI_EXPERIENCES_WITH_REVIEWS__HP
                                : interpolate(WIKI_EXPERIENCES_WITH_title, { title: article.instrumentalTitle })}
                        </h2>

                        {article.experiencesStarsAllowed && !isReviewHomepageOrCategoryOrProduct && (
                            <StarsRating
                                starsCount={article.experiencesScore}
                                label={WIKI_EXP_AVERAGE_SCORE}
                                className={styles.ArticleExperiences__starsRating}
                            />
                        )}

                        <div className={styles.ArticleExperiences__addExperience}>
                            <div className={styles.ArticleExperiences__addExperience__header}>
                                {isReviewHomepageArticle(article.category.slug, article.slug)
                                    ? WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_ANY_REVIEWABLE
                                    : interpolate(WIKI_DO_YOU_HAVE_EXPERIENCE_WITH_title, {
                                          title: article.instrumentalTitle,
                                      })}
                            </div>
                            <div className={styles.ArticleExperiences__addExperience__message}>
                                {WIKI_SHARE_YOUR_EXPERIENCE_AND_HELP_OTHERS}
                            </div>
                            <TrackClick
                                name="wiki_experience_add_clicked"
                                props={{
                                    article_id: article.id,
                                    category_slug: article.category.slug,
                                    article_slug: article.slug,
                                }}
                            >
                                <Btn
                                    className={styles.ArticleExperiences__addExperience__button}
                                    type={BtnType.Blue}
                                    icon={faComment}
                                    label={WIKI_WRITE_YOUR_EXPERIENCE}
                                    link={
                                        isAuthenticated(requestUser)
                                            ? addExpUrl
                                            : url(loginUrl, {},  {next: addExpUrl})
                                    }
                                />
                            </TrackClick>
                        </div>

                        {experiences.length > 1 && (
                            <div className={styles.ArticleExperiences__sorting}>
                                <SimpleSelect
                                    type="bigger"
                                    selected={this.state.sortExperiencesBy}
                                    options={sortOptions}
                                    onChange={this.onSortExperiencesByChange}
                                />
                            </div>
                        )}

                        {experiences.map((experience, id) => {
                            return (
                                <Experience
                                    key={id}
                                    experience={experience}
                                    isAdmin={isAdmin}
                                    isMobile={isMobile}
                                    onDeleteVendorReply={onDeleteVendorReply}
                                    onSaveVendorReply={onSaveVendorReply}
                                    onToggleMenuMore={onToggleMenuMore}
                                    showCompact={experience.article.id !== article.id}
                                />
                            );
                        })}
                    </div>
                )}
            </>
        );
    };

    private handleOnLoadMoreReviewCategoryArticles = () => {
        const { articleSlug, categorySlug, onLoadMoreReviewCategoryArticles, reviewCategoryArticles } = this.props;
        if (reviewCategoryArticles) {
            onLoadMoreReviewCategoryArticles(articleSlug, categorySlug, reviewCategoryArticles.length);
        }
    };

    private galleryLinkTo = (photofile: PhotoEntity) => {
        const { article } = this.props;
        return url(wikiArticlePhotoUrl, {
            categorySlug: article.category.slug,
            articleSlug: article.slug,
            photoId: photofile.id,
        });
    };

    private onClickSetState = (state) => {
        this.setState(state);
    };

    private onSortExperiencesByChange = (value) => {
        this.setState({
            sortExperiencesBy: value,
        });
    };
}

export default ReviewArticle;
